import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import GraphImage from '@graphcms/react-image';
import { Box, Typography } from '@mui/material';

import Markdown from '@/components/Markdown';
import Button from '@/components/Button';
import { BUTTON_TYPE } from '@/types';

import themes from '../../theme/helpers/blockThemes';

const Hero = ({
  alignment,
  title,
  description: { source },
  eyebrow,
  buttons,
  image: { handle, width, height, alt },
  sticker = {},
  theme,
  fullWidth,
}) => {
  const left = alignment === 'left';
  const blockTheme = themes[theme];
  const { handle: stickerHandle, alt: stickerAlt } = sticker || { handle: '', alt: '' };

  const useStyles = () => ({
    heroContainer: {
      pt: { xs: 4, md: 12 },
      pb: { xs: 9, md: 12 },
      backgroundColor: blockTheme.backgroundColor,
      mx: 'auto',
      px: {
        xs: 3,
        sm: 7,
        md: 10,
        xl: 15,
      },
      minHeight: { lg: '70vh' },
      display: 'flex',
      justifyContennt: 'center',
      alignItems: 'center',
    },
    heroInnerContainer: {
      mx: 'auto',
      px: {
        xs: 0,
        sm: 7,
        md: 0,
        lg: 5,
        xl: 10,
      },
      display: 'flex',
      flexDirection: {
        xs: 'column',
        md: left ? 'row' : 'row-reverse',
      },
      alignItems: 'center',
      height: 'fit-content',
      justifyContent: 'center',
      width: '100%',
    },
    fullHeroContainer: {
      height: { xs: '55vh', lg: '45vh' },
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fullHeroOverlay: {
      background: `rgba(35,49,82,.5)`,
      zIndex: 1,
    },
    fullHeroImage: {
      backgroundImage: {
        xs: `url("https://media.graphassets.com/resize=w:432,fit:crop/auto_image/${handle}")`,
        sm: `url("https://media.graphassets.com/resize=w:865,fit:crop/auto_image/${handle}")`,
        md: `url("https://media.graphassets.com/resize=w:1000,fit:crop/auto_image/${handle}")`,
        lg: `url("https://media.graphassets.com/resize=w:1200,fit:crop/auto_image/${handle}")`,
        xl: `url("https://media.graphassets.com/resize=w:1600,fit:crop/auto_image/${handle}")`,
      },
      backgroundPosition: 'top',
      backgroundPositionY: { xs: 0, xl: '-5vh' },
      backgroundSize: 'cover',
    },
    fullHeroBG: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    contentWrapper: {
      flexBasis: { md: '50%' },
      pt: { xs: 6, md: 0 },
      mr: {
        md: left ? 0 : 4,
        lg: left ? 0 : 10,
      },
      ml: {
        md: left ? 4 : 0,
        lg: left ? 10 : 0,
      },
      width: {
        xs: '100%',
        md: '50%',
      },
      maxWidth: 650,
    },
    copyWrapper: fullWidth
      ? {
          position: 'absolute',
          zIndex: 2,
          color: 'white',
          textAlign: 'center',
          mx: 3,
        }
      : {},
    sticker: {
      display: {
        xs: 'none',
        sm: 'flex',
      },
      zIndex: 2,
      position: 'absolute',
      bottom: {
        sm: -45,
        md: -60,
      },
      width: {
        sm: 150,
        md: 200,
      },
      height: {
        sm: 150,
        md: 200,
      },
      left: {
        sm: 16,
        md: 32,
      },
      '& img': {
        width: '100%',
        maxWidth: '100%',
      },
    },
    ctaWrapper: fullWidth
      ? {
          zIndex: 2,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }
      : {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          margin: { xs: 'auto', sm: 0 },
          pt: {
            xs: buttons ? 2 : 0,
            md: buttons ? 5 : 0,
          },
          alignItems: {
            xs: buttons ? 'center' : 'start',
            sm: 'start',
          },
        },
    buttonWrapper: {
      width: fullWidth
        ? {}
        : {
            xs: '100%',
            md: 'auto',
          },
      flexGrow: 1,
      maxWidth: fullWidth ? { sm: '250px' } : { md: '250px' },
      ...(fullWidth
        ? {
            mb: -3,
            mx: { xs: 4, sm: 'auto' },
          }
        : {
            mb: 1,
            mr: { md: 2 },
          }),
    },
  });

  const classes = useStyles({ theme });

  const HeroImage = () => (
    <Box
      width={{
        xs: '100%',
        md: '50%',
      }}
      position="relative"
      flexBasis={{ md: '50%' }}
      maxWidth={865}
    >
      <GraphImage
        image={{
          handle,
          height,
          width,
        }}
        alt={alt}
        maxWidth={865}
        withWebp
        blurryPlaceholder={false}
      />
    </Box>
  );

  const HeroCopy = () => {
    const eyebrowProps = { color: 'text.default' };
    const titleProps = fullWidth ? {} : { color: blockTheme.titleColor };
    const markdownParagraphProps = fullWidth ? { maxWidth: 525 } : { color: blockTheme.titleColor };
    return (
      <Box sx={{ ...classes.copyWrapper }}>
        <Box>
          {eyebrow && (
            <Typography variant="h4" component="h4" {...eyebrowProps}>
              {eyebrow}
            </Typography>
          )}
          <Typography variant="h1" component="h1" {...titleProps}>
            {title}
          </Typography>
        </Box>
        <Markdown
          source={source}
          theme={blockTheme}
          components={{
            p: props => (
              <Typography my={1} mx={0} component="p" variant="fullHeroMarkdown" {...markdownParagraphProps} {...props} />
            ),
          }}
        />
      </Box>
    );
  };

  const HeroCTA = () => (
    <Box sx={{ ...classes.ctaWrapper }}>
      {buttons.map(({ id: buttonId, label, href, color, variant }) => (
        <Box key={buttonId} sx={{ ...classes.buttonWrapper }}>
          <Button variant={variant} color={color} href={href} py={4} sx={{ 'border-radius': '0' }}>
            {label}
          </Button>
        </Box>
      ))}
    </Box>
  );

  return fullWidth ? (
    <Box sx={{ ...classes.fullHeroContainer }}>
      {/* Background Content */}
      <Box sx={{ ...classes.fullHeroOverlay, ...classes.fullHeroBG }} />
      <Box sx={{ ...classes.fullHeroImage, ...classes.fullHeroBG }} />

      <HeroCopy />

      {/* Sticker */}
      <Box sx={{ ...classes.sticker }}>
        <img alt={stickerAlt} src={`https://media.graphassets.com/${stickerHandle}`} />
      </Box>

      <HeroCTA />
    </Box>
  ) : (
    <Box sx={{ ...classes.heroContainer }}>
      <Box sx={{ ...classes.heroInnerContainer }}>
        <HeroImage />
        <Box sx={{ ...classes.contentWrapper }}>
          <HeroCopy />
          <HeroCTA />
        </Box>
      </Box>
    </Box>
  );
};

Hero.defaultProps = {
  eyebrow: '',
  sticker: {},
};

Hero.propTypes = {
  /* Text form of alignment. Expected left or right */
  alignment: oneOf(['left', 'right']).isRequired,
  title: string.isRequired,
  description: shape({
    source: shape({
      compiledSource: string.isRequired,
    }).isRequired,
  }).isRequired,
  eyebrow: string,
  image: shape({
    handle: string.isRequired,
    alt: string.isRequired,
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  /* Array of buttons. One object is one button */
  buttons: arrayOf(BUTTON_TYPE).isRequired,
  theme: string.isRequired,
  fullWidth: bool.isRequired,
  sticker: shape({
    handle: string,
    alt: string,
  }),
};

export default Hero;
